import React, { useState, useEffect, useRef } from 'react';
import StyledBox from 'views/common/components/UI/TourPackagesWidget/TourPackagesWidget.styles';
import { useTranslation } from 'next-i18next';
import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import RadioButtonCardOld from 'views/common/components/UI/RadioButtonCardOld';
import {
  BestPriceGuarantee,
  Counter,
  FormErrors,
} from 'views/common/components';
import {
  TourPackageSelectorItem,
  OccurrenceMenu,
  TourPackagesWidgetDatePicker,
} from 'views/common/components/UI/TourPackagesWidget';
import { WidgetNotificationBox } from 'views/common/components/UI/MainWidget';

const TourPackagesWidget = (props) => {
  const {
    bookingDetails,
    includeBike,
    onIncludeBikeChange,
    onBookTour,
    bookingError,
    startingPrice,
    disableDates,
    getBookingDetails,
    acceptableReservationsType,
    showGuarantee = true,
    showBookBtn = true,
    showTitle = true,
    stretchedWidget = false,
    getTourBookingData = () => {},
    disableTourPackageCounter = false,
    tourWidgetNotifications = [],
    handleShowActionBar = () => {},
  } = props;

  const { t } = useTranslation('fe_er_common_tour_packages_widget');
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const tourPackagesWidgetRef = useRef();
  const [widgetData, setWidgetData] = useState(
    bookingDetails
      ? {
        package_id: bookingDetails.selected_package_id,
        occurrence_id: bookingDetails.occurrence_id,
        packages_count: 1,
        pickup_date: bookingDetails.pickup_date,
        reservation_identifier: bookingDetails.reservation_identifier,
      }
      : {},
  );

  const clearData = () => {
    setWidgetData((prev) => ({
      ...prev,
      package_id: null,
    }));
  };

  const handleWidgetDataChange = (obj) => {
    setWidgetData((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const handleDateChange = (date) => {
    const formatedDate = dayjs(date).format('YYYY-MM-DD');
    handleWidgetDataChange({ pickup_date: formatedDate });
    getBookingDetails({
      ...widgetData,
      pickup_date: formatedDate,
      with_motorcycle: includeBike,
    });
  };

  const handlePackageChange = (id) => {
    handleWidgetDataChange({ package_id: id });
  };

  const handleOccurenceChange = (e) => {
    const occurence = bookingDetails.occurrences.find(
      (occ) => occ.id === e.target.value,
    );

    handleWidgetDataChange({
      occurrence_id: occurence.id,
      pickup_date: occurence.start_date,
    });

    getBookingDetails({
      ...widgetData,
      occurrence_id: occurence.id,
      pickup_date: occurence.start_date,
      with_motorcycle: includeBike,
    });
  };

  const handleDecrement = () => {
    handleWidgetDataChange({ packages_count: widgetData.packages_count - 1 });
  };

  const handleIncrement = () => {
    handleWidgetDataChange({ packages_count: widgetData.packages_count + 1 });
  };

  const handleBookTour = () => {
    onBookTour(widgetData);
  };

  const getOccurrenceName = (occurrence) => {
    const parts = [dayjs(occurrence.start_date).format('MMM D, YYYY')];

    if (occurrence.soldout) {
      parts.push(`(${t('fe_er_common_tour_packages_widget:sold_out')})`);
    } else if (
      occurrence.maximum_vehicles_allowed
      && occurrence.maximum_vehicles_allowed <= 5
    ) {
      if (occurrence.notes) {
        parts.push(`(${occurrence.notes})`);
      }
      parts.push(
        `(${t('fe_er_common_tour_packages_widget:only')} ${
          occurrence.maximum_vehicles_allowed
        } ${t('fe_er_common_tour_packages_widget:left')})`,
      );
    } else {
      if (occurrence.discount_percent) {
        parts.push('-');
        parts.push(
          `${occurrence.discount_percent}% ${t(
            'fe_er_common_tour_packages_widget:occurrence:off',
          )}`,
        );
      }

      if (occurrence.notes) {
        parts.push(`(${occurrence.notes})`);
      }
    }

    return parts.join(' ');
  };

  const handleIncludeBikeChange = (value) => {
    if (acceptableReservationsType === 'any_reservations') {
      onIncludeBikeChange(value);
      getBookingDetails({ with_motorcycle: value, ...widgetData });
      clearData();
    }
  };
  const scrollCallBack = (entries) => {
    if (entries[0].isIntersecting) {
      handleShowActionBar(false);
    } else {
      handleShowActionBar(true);
    }
  };

  useEffect(() => {
    const scroll = new IntersectionObserver(scrollCallBack, {
      threshold: 0.7,
    });
    if (tourPackagesWidgetRef.current) {
      scroll.observe(tourPackagesWidgetRef.current);
    }
    return () => {
      if (tourPackagesWidgetRef.current) {
        window.scroll.unobserve(tourPackagesWidgetRef.current);
      }
    };
  }, [tourPackagesWidgetRef]);
  useEffect(() => {
    if (widgetData) {
      getTourBookingData(widgetData);
    }
  }, [widgetData]);

  return (
    <StyledBox
      className={stretchedWidget && 'TDAPWStrechedWided'}
      ref={tourPackagesWidgetRef}>
      <Box>
        {(tourWidgetNotifications.length > 0 || bookingError?.errors) && (
          <Grid item xs={12}>
            {bookingError?.errors?.base && (
              <Box>
                <WidgetNotificationBox
                  title={t('fe_er_common_main_widget:base_error_header')}
                  notificationsList={bookingError.errors.base}
                />
              </Box>
            )}
            {tourWidgetNotifications.length > 0 && (
              <Box>
                <WidgetNotificationBox
                  notificationsList={tourWidgetNotifications}
                />
              </Box>
            )}
          </Grid>
        )}
        {showTitle && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography500 variant="h4">
                {t('fe_er_common_tour_packages_widget:book_your_tour')}
              </Typography500>
            </Grid>

            <Grid item xs={12} md={5} align={!smallScreen ? 'right' : ''}>
              {startingPrice && (
                <Typography variant="h6">
                  {`${t('fe_er_common_tour_packages_widget:starting_at')} `}
                  <Typography500 variant="h5" component="span">
                    {moneyFormat(
                      Dinero({
                        amount: startingPrice.amount,
                        currency: startingPrice.currency_code,
                        precision: startingPrice.precision,
                      }),
                    )}
                  </Typography500>
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        {bookingDetails
          && bookingDetails.tour.type_identifier !== 'self_drive' && (
            <Box my={2}>
              <Alert
                severity="info"
                icon={<ErrorIcon className="TDAPWAlertIcon" />}>
                {t('fe_er_common_tour_packages_widget:guided_note')}
              </Alert>
            </Box>
        )}
        <Box my={3}>
          {bookingDetails
          && bookingDetails.tour.type_identifier === 'self_drive' ? (
            <>
              {bookingDetails?.calendar_options && (
                <Tooltip
                  arrow
                  title={t(
                    'fe_er_common_tour_packages_widget:minimum_deposit',
                    {
                      deposit_percent:
                        bookingDetails.group_promotion.deposit_percent,
                    },
                  )}
                  className="TDAPWTooltip">
                  <TourPackagesWidgetDatePicker
                    calendarOptions={bookingDetails.calendar_options}
                    handleDateChange={handleDateChange}
                    disableDates={disableDates}
                    pickupDate={widgetData.pickup_date}
                    label={t(
                      'fe_er_common_tour_packages_widget:tour_start_date',
                    )}
                  />
                </Tooltip>
              )}
              {bookingError?.errors && (
                <FormErrors errorMsgs={bookingError} errorField="pickup_date" />
              )}
            </>
            ) : (
              <>
                {bookingDetails?.occurrences
                && bookingDetails.occurrences.length > 0 && (
                  <Tooltip
                    arrow
                    title={t(
                      'fe_er_common_tour_packages_widget:minimum_deposit',
                      {
                        deposit_percent:
                          bookingDetails.group_promotion.deposit_percent,
                      },
                    )}
                    className="TDAPWTooltip">
                    <OccurrenceMenu
                      occurrences={bookingDetails.occurrences}
                      handleOccurenceChange={handleOccurenceChange}
                      occurrenceId={widgetData.occurrence_id}
                      label={t(
                        'fe_er_common_tour_packages_widget:tour_start_date',
                      )}
                      getOccurrenceName={getOccurrenceName}
                    />
                  </Tooltip>
                )}

                {bookingError?.errors && (
                <FormErrors
                  errorMsgs={bookingError}
                  errorField="occurrence_id"
                />
                )}
              </>
            )}
        </Box>
        <Box my={2}>
          {(acceptableReservationsType
            === 'with_motorcycle_reservations_only'
            || acceptableReservationsType === 'any_reservations') && (
            <Typography500 variant="h5">
              {t('fe_er_common_tour_packages_widget:bike_selection')}
            </Typography500>
          )}
          <Box my={2}>
            <Grid container spacing={2}>
              {(acceptableReservationsType
                === 'with_motorcycle_reservations_only'
                || acceptableReservationsType === 'any_reservations') && (
                <Grid
                  item
                  xs={12}
                  md={
                    acceptableReservationsType === 'any_reservations'
                    || stretchedWidget
                      ? 6
                      : 12
                  }>
                  <RadioButtonCardOld
                    title={t('fe_er_common_tour_packages_widget:include_bike')}
                    active={includeBike}
                    onClick={() => handleIncludeBikeChange(true)}
                    size="small"
                    radioBtnPostion="left"
                  />
                </Grid>
              )}

              {(acceptableReservationsType
                === 'without_motorcycle_reservations_only'
                || acceptableReservationsType === 'any_reservations') && (
                <Grid
                  item
                  xs={12}
                  md={
                    acceptableReservationsType === 'any_reservations'
                    || stretchedWidget
                      ? 6
                      : 12
                  }
                  className="TDAPWBringOwnContainer">
                  <RadioButtonCardOld
                    title={t('fe_er_common_tour_packages_widget:bring_my_own')}
                    active={!includeBike}
                    onClick={() => handleIncludeBikeChange(false)}
                    size="small"
                    radioBtnPostion="left"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box my={2} id="select_tour_package">
          <Typography500 variant="h5" className="TDAPWTourPackageTitle">
            {t('fe_er_common_tour_packages_widget:select_tour_package')}
          </Typography500>

          {bookingDetails?.packages && (
            <Box mt={3}>
              <Grid container spacing={2}>
                {bookingDetails.packages.map((pack, index) => (
                  <Grid
                    item
                    key={pack.id}
                    xl={stretchedWidget ? 4 : 12}
                    lg={stretchedWidget ? 4 : 12}
                    md={12}
                    sm={12}>
                    <TourPackageSelectorItem
                      index={index}
                      id={pack.id}
                      adultsNumber={pack.people_count}
                      numberOfMoto={pack.motorcycles_count}
                      numberOfBed={pack.beds_count}
                      pricePerPerson={moneyFormat(
                        Dinero({
                          amount: pack.price_per_person.amount,
                          currency: pack.price_per_person.currency_code,
                        }),
                      )}
                      active={pack.id == widgetData.package_id}
                      onClick={handlePackageChange}
                    />
                  </Grid>
                ))}
              </Grid>
              {bookingError?.errors && (
                <FormErrors errorMsgs={bookingError} errorField="package_id" />
              )}
            </Box>
          )}
        </Box>
        <Box my={3}>
          <Box className="TDABWNumberOfTours">
            <Box>
              <Typography500 variant="h6">
                {t('fe_er_common_tour_packages_widget:number_tour_packages')}
              </Typography500>
            </Box>
            <Box>
              <Counter
                count={widgetData.packages_count || 1}
                min={1}
                max={10}
                onIncrement={handleIncrement}
                onDecrement={handleDecrement}
                inactive={disableTourPackageCounter}
              />
            </Box>
          </Box>
          {bookingError?.errors && (
            <FormErrors errorMsgs={bookingError} errorField="packages_count" />
          )}
        </Box>
        {showGuarantee && (
          <Box my={2} display="flex" justifyContent="center">
            <BestPriceGuarantee />
          </Box>
        )}
        {showBookBtn && (
          <Box my={2}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              onClick={handleBookTour}
              color="primary">
              {t('fe_er_common_tour_packages_widget:book_tour')}
            </Button>
          </Box>
        )}
        <Typography variant="caption" className="TDAPWNotCharged">
          {t('fe_er_common_tour_packages_widget:wont_charged_yet')}
        </Typography>
      </Box>
    </StyledBox>
  );
};

export { TourPackagesWidget };
