import React from 'react';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import Container from '@mui/material/Container';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import PATHS from 'lib/navigation/paths';
import { useSelector } from 'react-redux';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import useGenerateErrorPageHreflangs from 'views/common/hooks/useGenerateErrorPageHreflangs';

const PREFIX = 'ErrorPage';

const classes = {
  root: `${PREFIX}-root`,
  bgWrap: `${PREFIX}-bgWrap`,
  bgContent: `${PREFIX}-bgContent`,
  statusCode: `${PREFIX}-statusCode`,
  panel: `${PREFIX}-panel`,
  header: `${PREFIX}-header`,
  description: `${PREFIX}-description`,
  phones: `${PREFIX}-phones`,
  button: `${PREFIX}-button`,
  btnWrap: `${PREFIX}-btnWrap`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
  },

  [`& .${classes.bgWrap}`]: {
    height: 'calc(100vh - 112px)',
    width: '100vw',
    overflow: 'hidden',
    zIndex: -1,
  },

  [`& .${classes.bgContent}`]: {
    width: theme.typography.pxToRem(640),
    position: 'absolute',
    bottom: theme.spacing(12),
    margin: 0,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: '3rem',
    textAlign: 'left',
    paddingTop: '40vh',
    [`& .${classes.statusCode}`]: {
      fontSize: theme.typography.pxToRem(150),
      color: theme.palette.common.white,
    },
    [`& .${classes.panel}`]: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      color: theme.palette.common.white,
      padding: theme.spacing(4, 2),
      textAlign: 'left',
      [`& .${classes.header}`]: {
        fontSize: theme.typography.pxToRem(29),
        textTransform: 'uppercase',
        marginBottom: theme.spacing(1.5),
      },
      [`& .${classes.description}`]: {
        fontSize: theme.typography.pxToRem(14),
        marginBottom: theme.spacing(0.5),
      },
      [`& .${classes.phones}`]: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
      },
      [`& .${classes.btnWrap}`]: {
        textAlign: 'right',
        [`& .${classes.button}`]: {
          fontSize: theme.typography.pxToRem(18),
          padding: theme.spacing(0.5, 2),
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  },
}));

const ErrorPage = ({ statusCode = 500, title, subtitle }) => {
  const { t } = useTranslation();
  const appState = useSelector((state) => state.globalData.appState);
  const { hreflangData } = useSelector((state) => state.globalData);
  useGenerateErrorPageHreflangs();

  return (
    <Root className={classes.root}>
      {hreflangData && (
        <>
          <link rel="canonical" href={hreflangData?.canonical} />
          {hreflangData.urls?.map((local) => (
            <link
              key={local.hreflang}
              rel="alternate"
              href={local.url}
              hrefLang={local.hreflang}
            />
          ))}
        </>
      )}
      <div className={classes.bgWrap}>
        <Image
          alt="error"
          src="/imgs/error.jpg"
          layout="fill"
          objectFit="cover"
          quality={100}
          unoptimized
        />
      </div>
      <Container maxWidth="xxl">
        <div className={classes.bgContent}>
          <span className={classes.statusCode}>{statusCode}</span>
          <div className={classes.panel}>
            <Typography className={classes.header}>
              {title
                || t(`fe_er_common_layout:errors.error_${statusCode}.header`)}
            </Typography>
            <Typography className={classes.description}>
              {subtitle
                || t(`fe_er_common_layout:errors.error_${statusCode}.description`)}
            </Typography>
            {appState?.help_tout
              && appState.help_tout.phones
              && Array.isArray(appState.help_tout.phones) && (
                <Typography color="primary" className={classes.phones}>
                  {appState.help_tout.phones.join(' | ')}
                </Typography>
            )}
            <div className={classes.btnWrap}>
              <Button
                href={PATHS.home()}
                variant="contained"
                color="primary"
                className={classes.button}
                component={NavigatorLink}>
                {t('fe_er_common_layout:errors.home')}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Root>
  );
};
export { ErrorPage };
export default ErrorPage;
