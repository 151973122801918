import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  FormHelperText,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import axios, { API } from 'lib/config/axios';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useDispatch, useSelector } from 'react-redux';
import getConfig from 'next/config';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import { setAppData } from 'redux/features/globalData-slice';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'next-i18next';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import SocialLogin from 'views/common/components/Logical/Auth/SocialLogin';
import PATHS from 'lib/navigation/paths';
import { isLocalStorageAvailable } from 'lib/utils/localStroage';
import { SignTextField } from 'views/user-module/common/components/UI/SignTextField';

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const Root = styled('div')(({ theme }) => ({
  '& .checkBox': {
    marginLeft: '-12px',
  },
  '& .checkBoxLabel': {
    color: theme.palette.grey[300],
    marginTop: theme.spacing(1),

    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  '& .linkBold': {
    textTransform: 'uppercase',
    textDecoration: 'underline',
    fontWeight: '700',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  '& .signIn': {
    letterSpacing: theme.spacing(0.125),
    fontWeight: '500',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  '& .SUFDivider.MuiDivider-root': {
    background: 'none',
    color: theme.palette.grey[300],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&::before,&::after': {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const SignUpForm = (props) => {
  const {
    redirectCallback,
    showSocialLogin = true,
    showSignInLink = true,
    signInLinkAction,
  } = props;
  const { t } = useTranslation('fe_er_auth');
  const appState = useSelector((state) => state.globalData.appState);
  const dispatch = useDispatch();
  const appNavigator = useAppNavigator();
  const recaptchaRef = useRef();

  const handleErrorResponse = useErrorsHandler();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    opt_in_email_specials: true,
    terms_and_conditions: false,
  });
  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: !formData[e.target.name],
    });
  };

  const handleSignUp = (token) => {
    const localStorageAvailable = isLocalStorageAvailable();
    axios
      .post(API.users.signUp, {
        authenticity_token: token,
        user: {
          ...formData,
        },
        referral_code: localStorageAvailable
          ? localStorage.getItem('referral_code')
          : null,
      })
      .then((res) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: 'success',
            message: 'Welcome! You have signed up successfully.',
          }),
        );
        if (typeof ReactNativeWebView !== 'undefined') {
          // eslint-disable-next-line no-undef
          ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'login',
              data: res.data,
            }),
          );
        }
        dispatch(setAppData({ ...appState, ...res.data }));
        redirectCallback(res.data);
        localStorageAvailable && localStorage.removeItem('referral_code');
        dispatch(setLoading(false));
      })
      .catch((error) => {
        try {
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
          switch (error.response.status) {
            case 406:
              setErrors(error.response.data.errors);
              break;
            default:
              handleErrorResponse(error);
          }
        } catch (e) {
          handleErrorResponse(error);
        }
        dispatch(setLoading(false));
      });
  };

  const getToken = () => {
    dispatch(setLoading(true));
    axios
      .get(API.authenticityToken)
      .then((res) => {
        handleSignUp(res.data.authenticity_token);
      })
      .catch((error) => {
        handleErrorResponse(error);
        dispatch(setLoading(false));
      });
  };
  const onRecaptchChange = (token) => {
    setFormData({
      ...formData,
      'g-recaptcha-response': token,
    });
  };
  const goToSignin = () => {
    appNavigator.push(PATHS.motorcycleClub.signIn({ ...appNavigator.query }));
  };
  useEffect(() => {
    if (appState?.current_user) {
      appNavigator.push(PATHS.home());
    }
  }, []);
  return (
    <>
      <Root>
        <Box mt={2}>
          <Grid container spacing={1.5}>
            <Grid item xs={12} md={6}>
              <SignTextField
                variant="filled"
                type="text"
                fullWidth
                color="secondary"
                name="first_name"
                first_name
                label={t('fe_er_auth:first_name')}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name || ''}
                value={formData.first_name}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SignTextField
                variant="filled"
                type="text"
                error={Boolean(errors.last_name)}
                helperText={errors.last_name || ''}
                fullWidth
                color="secondary"
                name="last_name"
                value={formData.last_name}
                label={t('fe_er_auth:last_name')}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SignTextField
                variant="filled"
                type="email"
                error={Boolean(errors.email)}
                helperText={errors.email || ''}
                fullWidth
                color="secondary"
                name="email"
                value={formData.email}
                label={t('fe_er_auth:email')}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SignTextField
                variant="filled"
                type="password"
                fullWidth
                color="secondary"
                name="password"
                error={Boolean(errors.password)}
                helperText={errors.password || ''}
                value={formData.password}
                label={t('fe_er_auth:password')}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>
        </Box>

        {appState?.settings && appState.settings.captcha_enabled && (
          <Box mt={2}>
            <FormControl error={Boolean(errors['g-recaptcha-response'])}>
              <ReCAPTCHA
                sitekey={envVars.RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
                onChange={onRecaptchChange}
              />
              {errors['g-recaptcha-response'] && (
                <FormHelperText>
                  {errors['g-recaptcha-response']}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        )}

        <FormControl error={Boolean(errors.opt_in_email_specials)}>
          <Box mt={2} display="flex" alignItems="flex-start">
            <Checkbox
              className="checkBox"
              name="opt_in_email_specials"
              checked={formData.opt_in_email_specials}
              color="default"
              onChange={handleCheckboxChange}
            />

            <Typography className="checkBoxLabel" variant="body1">
              {t('fe_er_auth:email_subscribtion_checkbox')}
            </Typography>
          </Box>
        </FormControl>

        <FormControl error={Boolean(errors.terms_and_conditions)}>
          <Box mt={2} display="flex" alignItems="flex-start">
            <Checkbox
              className="checkBox"
              name="terms_and_conditions"
              checked={formData.terms_and_conditions}
              color="default"
              onChange={handleCheckboxChange}
            />

            <Typography className="checkBoxLabel" variant="body1">
              {t('fe_er_auth:privacy_policy_checkbox')}
              <NavigatorLink
                target="_blank"
                className="linkBold"
                href="https://www.eaglerider.com/privacy-policy">
                {'EagleRider '}
                {t('fe_er_auth:privacy_policy')}
              </NavigatorLink>
              {t('fe_er_auth:and')}
              <NavigatorLink
                target="_blank"
                className="linkBold"
                href="https://support.eaglerider.com/eagleshare-privacy-policy">
                {'EagleShare '}
                {t('fe_er_auth:privacy_policy')}
              </NavigatorLink>
              .
            </Typography>
          </Box>
          {errors.terms_and_conditions && (
            <FormHelperText>{errors.terms_and_conditions}</FormHelperText>
          )}
        </FormControl>

        <Box mt={2}>
          <Button
            onClick={getToken}
            fullWidth
            variant="contained"
            color="primary">
            {t('fe_er_auth:create_account')}
          </Button>
        </Box>

        {showSocialLogin && (
          <>
            <Divider className="SUFDivider">{t('fe_er_auth:or')}</Divider>
            {/* NB: login with gmail facebook */}
            <SocialLogin />
          </>
        )}

        {showSignInLink && (
          <Box
            display="flex"
            alignItems="center"
            justifycontent="center"
            flexWrap="wrap"
            style={{ justifyContent: 'center' }}>
            <Box textAlign="center">
              <Typography variant="body1">
                {t('fe_er_auth:already_have_account')}
              </Typography>
            </Box>
            <Button
              variant="text"
              color="secondary"
              className="signIn"
              onClick={signInLinkAction || goToSignin}>
              {t('fe_er_auth:sign_in')}
            </Button>
          </Box>
        )}
      </Root>
    </>
  );
};

export default SignUpForm;
