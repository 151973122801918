/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import dayjs from 'dayjs';
import axios, { API } from 'lib/config/axios';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import {
  LOCATION_TYPE,
  MAX_DISTANCE,
  RENTAL_WIDGET_FIELDS,
  ERRORS_PREFIX,
} from 'lib/utils/mainWidgetConstants';
import { RentalWidget } from 'views/common/components';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import { useTranslation } from 'next-i18next';
import { localeDateFormatMap } from 'lib/config/date-picker';
import useHandleNavigationToRPPage from 'views/common/hooks/useHandleNavigationToRPPage';
import PATHS from 'lib/navigation/paths';
import useCookies from 'views/common/hooks/useCookies';
import { WL_SS_KEY } from 'views/common/components/Logical/MainWidgetWrapper/utils/constants';

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

const { envVars } = require('next/config').default().publicRuntimeConfig;

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

let timeoutId = '';
let controller;

const rentalWidgetDummyData = {
  pickup: { locations: [], time_slots: [] },
  dropoff: { locations: [], time_slots: [] },
  locations_geographical_data: [],
};
const locationsDummyData = {
  pickup: [],
  dropoff: [],
};

const RentalWidgetWrapper = (props) => {
  const {
    widgetType,
    handleWidgetTypeChange,
    vertical,
    reservationData,
    handleRentalWidgetSearchClick,
    rentalWidgetErrors,
    handleRentalWidgetDataChange,
    handleRenderAllWidget,
    firstCallParams,
    disableVehicleCount,
    actionButtonCustomLabel,
    reInitWidget,
    isDealWidget,
    updateRentalWidgetWarnings,
    hiddenVehicleCount,
    hiddenSearchBtn,
    widgetNotifications = [],
    getFormData = () => {},
    autoOpenDropoffDate = false,
    updateRenderAllProductOptions,
    rentalWidgetInitData,
    ...other
  } = props;
  const handleErrorResponse = useErrorsHandler();
  const recaptchaRef = useRef();

  const { t } = useTranslation();
  const appNavigator = useAppNavigator();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [, setCookie, removeCookie] = useCookies();
  const { isCrawler, appState, isInIframe } = useSelector(
    (state) => state.globalData,
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [, setLoaded] = useState(isCrawler);
  const { navigateToNextPage } = useHandleNavigationToRPPage();
  const initFormData = () => {
    const newFormData = {};
    Object.entries(appNavigator.query).forEach(([key, value]) => {
      if (!key.includes('slug') && !!value) {
        newFormData.dataFromQuery = true;
        let processedKey = key;
        if (key === '[reservation][vehicle_id]') {
          processedKey = 'reservation[vehicle_id]';
        }
        const formatedKeys = processedKey.match(/(.+)\[(.+)\]/);
        if (formatedKeys?.length === 3) {
          if (!newFormData[formatedKeys[1]]) newFormData[formatedKeys[1]] = {};
          const valueToAssign = isNaN(value) ? value : Number(value);
          newFormData[formatedKeys[1]][formatedKeys[2]] = formatedKeys[2].endsWith('date')
            ? dayjs(valueToAssign, 'MM/DD/YYYY')
            : valueToAssign;
        } else {
          newFormData[key] = value;
        }
      }
    });
    return {
      ...newFormData,
      reservation: {
        vehicles_count: 1,
        ...newFormData?.reservation,
        ...reservationData,
      },
    };
  };
  const [mainWidgetData, setMainWidgetData] = useState(rentalWidgetDummyData);
  const [openDropoffDatePicker, setOpenDropoffDatePicker] = useState(false);

  const [formData, setFormData] = useState(initFormData());
  const [locations, setLocations] = useState(locationsDummyData);
  const [isOptionsLoading, setIsOptionsLoading] = useState({
    [LOCATION_TYPE.PICKUP]: false,
    [LOCATION_TYPE.DROPOFF]: false,
  });
  const [returnToDifferentLocation, setReturnToDifferentLocation] = useState(true);
  const [hasToReturnToDifferentLocation, setHasToReturnToDifferentLocation] = useState(false);
  const [errors, setErrors] = useState({});

  const rentalWidgetNotifications = useMemo(
    () => (mainWidgetData?.pickup?.location_blackout_reopen_date
      ? [
        t('fe_er_common_main_widget:closed_alert', {
          date: dayjs(
            mainWidgetData.pickup.location_blackout_reopen_date,
            'YYYY-MM-DD',
          ).format('MMM D, YYYY'),
        }),
        ...widgetNotifications,
      ]
      : [...widgetNotifications]),
    [
      mainWidgetData?.pickup?.location_blackout_reopen_date,
      widgetNotifications,
    ],
  );
  const resetOpenDropoffDatePicker = () => {
    if (autoOpenDropoffDate) {
      setOpenDropoffDatePicker(false);
    }
  };
  const prepareQueryFromObj = (params) => {
    if (!params) return {};

    const queryObj = {};
    const unNestedParams = ['referral_code'];
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === 'object') {
        Object.entries(value).forEach(
          ([nestedObjectKey, nestedObjectValue]) => {
            if (unNestedParams.includes(nestedObjectKey)) {
              queryObj[nestedObjectKey] = nestedObjectValue;
            } else {
              queryObj[`${key}[${nestedObjectKey}]`] = nestedObjectValue;
            }
          },
        );
      } else {
        queryObj[key] = value;
      }
    });
    return queryObj;
  };
  const disableDate = (date, disabledWeekDays, disabledIntervals) => {
    let found;
    if (disabledWeekDays) {
      found = disabledWeekDays.findIndex(
        (interval) => interval.days.findIndex((day) => day == date.get('day')) != -1
          && date.isSameOrAfter(interval.start)
          && date.isSameOrBefore(interval.end),
      );
      if (found != -1) return true;
    }

    if (disabledIntervals) {
      found = disabledIntervals.findIndex(
        (interval) => date.isSameOrAfter(interval.start)
          && date.isSameOrBefore(interval.end),
      );
      if (found != -1) return true;
    }

    return false;
  };

  const verifyLocationFields = (newLocations, key, newFormData) => {
    if (key === RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID) {
      const dropoffLoc = newLocations.dropoff.find(
        (loc) => loc.id
          === newFormData.reservation[RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID],
      );
      if (!dropoffLoc) {
        setFormData((prevData) => ({
          ...prevData,
          reservation: {
            ...prevData.reservation,
            [RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID]:
              newFormData.reservation[RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID],
            [RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID]:
              newFormData.reservation[RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID],
          },
        }));
      }
    }
  };
  const shouldDisableDate = (type, date, newWidgetData) => {
    const widgetData = newWidgetData || mainWidgetData;

    if (type === LOCATION_TYPE.PICKUP) {
      return disableDate(
        date,
        widgetData.pickup.disabled_week_days,
        widgetData.pickup.disabled_intervals,
      );
    }
    return disableDate(
      date,
      widgetData.dropoff.disabled_week_days,
      widgetData.dropoff.disabled_intervals,
    );
  };
  const updateWidgetData = (res, newFormData, key) => {
    setMainWidgetData({
      ...res.data.rental_widget,
      show_widget_group_discounts: res.data.show_widget_group_discounts,
    });

    setFormData((prevData) => ({
      ...prevData,
      reservation: {
        ...prevData.reservation,
        [RENTAL_WIDGET_FIELDS.PICKUP_DATE]: dayjs(
          res.data.rental_widget.pickup.selected_date,
          localeDateFormatMap[appNavigator.locale],
        ),
        [RENTAL_WIDGET_FIELDS.DROPOFF_DATE]: dayjs(
          res.data.rental_widget.dropoff.selected_date,
          localeDateFormatMap[appNavigator.locale],
        ),
        [RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT]:
          res.data.rental_widget.pickup.selected_time_slot,
        [RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT]:
          res.data.rental_widget.dropoff.selected_time_slot,
        [RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID]:
          res.data.rental_widget.pickup.location_id,
        [RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID]:
          res.data.rental_widget.dropoff.location_id,
      },
    }));
    if (
      isDealWidget
      && res.data.rental_widget.pickup.location_id
      && !res.data.rental_widget.dropoff.location_id
    ) {
      const canReturnToSameLocation = res.data.rental_widget.dropoff.locations.some(
        (location) => location.id === res.data.rental_widget.pickup.location_id,
      );
      if (!canReturnToSameLocation) {
        setReturnToDifferentLocation(true);
        setHasToReturnToDifferentLocation(true);
      } else {
        if (isMobile) {
          setReturnToDifferentLocation(false);
        }
        setHasToReturnToDifferentLocation(false);
      }
    }
    const newLocations = {
      pickup: res.data.rental_widget.pickup.locations,
      dropoff: res.data.rental_widget.dropoff.locations,
    };
    setLocations(newLocations);
    updateRentalWidgetWarnings(
      res.data.rental_widget.warnings?.length > 0
        ? [
          ...res.data.rental_widget.warnings,
          t('fe_er_common_main_widget:warning_note'),
        ]
        : [],
    );
    updateRenderAllProductOptions(res.data.rental_widget.show_product_options);
    verifyLocationFields(newLocations, newFormData, key);
    if (typeof handleRentalWidgetDataChange === 'function') {
      handleRentalWidgetDataChange();
    }
    if (!rentalWidgetErrors) {
      setErrors({});
    }
    setLoaded(true);
  };
  const getRentalWidgetData = (newParamsData, newFormData, key) => {
    if (isCrawler) return;
    dispatch(setLoading(true));
    axios
      .get(API.rentalWidget, {
        params: prepareQueryFromObj(newParamsData),
      })
      .then((res) => {
        updateWidgetData(res, newFormData, key);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const getReferralCode = () => {
    const codeFromSession = sessionStorage.getItem(WL_SS_KEY);
    return (
      formData.reservation?.referral_code
      || appNavigator.query.ReferralCode
      || codeFromSession
    );
  };

  const createReservation = (recaptchaToken) => {
    if (typeof handleRentalWidgetSearchClick === 'function') {
      handleRentalWidgetSearchClick(formData);
      return;
    }
    dispatch(setLoading(true));
    axios
      .post(API.reservations.create, {
        authenticity_token: appState.authenticity_token,
        'g-recaptcha-response': recaptchaToken,
        user_agent: window?.navigator?.userAgent,
        reservation: {
          ...formData.reservation,
          referral_code: getReferralCode(),
          dropoff_date: dayjs(formData.reservation.dropoff_date || null).format(
            localeDateFormatMap[appNavigator.locale],
          ),
          pickup_date: dayjs(formData.reservation.pickup_date || null).format(
            localeDateFormatMap[appNavigator.locale],
          ),
        },
      })
      .then((res) => {
        const { paused } = res.data.user_membership;
        if (paused) {
          dispatch(
            setSnackbar({
              open: true,
              severity: 'warning',
              message: t('fe_er_credit_cards_page:change_billing_card_msg'),
            }),
          );
        }
        window.gtag('event', 'start_reservation', {
          ...formData.reservation,
          dropoff_date: dayjs(formData.reservation.dropoff_date || null).format(
            localeDateFormatMap[appNavigator.locale],
          ),
          pickup_date: dayjs(formData.reservation.pickup_date || null).format(
            localeDateFormatMap[appNavigator.locale],
          ),
        });
        if (isInIframe) {
          if (formData.reservation.referral_code) {
            sessionStorage.setItem(
              WL_SS_KEY,
              formData.reservation.referral_code,
            );
          }
          removeCookie('reservation');
          setCookie('reservation', res.data.reservation.identifier, {
            path: '/',
            sameSite: 'none',
            secure: true,
          });
        }
        navigateToNextPage(
          res.data.reservation,
          res.data.referral && {
            ...res.data.referral,
            code: formData.reservation.referral_code,
          },
          isInIframe ? mainWidgetData.continue_reservation_inside_widget : true,
        );
      })
      .catch((error) => {
        dispatch(setLoading(false));
        const statusCode = error.response.status;
        const validationErrors = error.response.data?.errors?.reservation;

        switch (statusCode) {
          case 406:
            setErrors(() => {
              const newErrors = {};
              Object.entries(validationErrors).forEach(([key, value]) => {
                if (key === 'base') {
                  newErrors[`${ERRORS_PREFIX}base`] = value;
                } else {
                  newErrors[`${ERRORS_PREFIX}${key}`] = value.join(', ');
                }
              });
              return newErrors;
            });
            break;

          default:
            handleErrorResponse(error);
            break;
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      });
  };

  const handleSearchButtonClick = (recaptchaToken) => {
    // ? This is a temp fix to redirect to offroad page incase of offroad location

    const location = mainWidgetData.pickup.locations.find(
      (loc) => loc.id == formData.reservation.pickup_location_id,
    );
    if (location?.products?.includes('offroad_guided_tour')) {
      if (isInIframe) {
        window.open(
          appNavigator.appendQuery(
            appNavigator.getUrl(
              undefined,
              PATHS.guidedOffroadTour.slug(
                'las-vegas-mojave-desert-off-road-tour',
              ).url,
              false,
            ),
            reservationData?.referral_code
              ? {
                referral_code: reservationData?.referral_code,
              }
              : null,
          ),
          '_blank',
          'noopener,noreferrer',
        );
      } else {
        appNavigator.push(
          PATHS.guidedOffroadTour.slug(
            'las-vegas-mojave-desert-off-road-tour',
            reservationData?.referral_code
              ? {
                referral_code: reservationData?.referral_code,
              }
              : null,
          ),
        );
      }
    } else {
      createReservation(recaptchaToken);
    }
  };

  const handleReturnToDifferentLocation = (value, nothingToDo) => {
    setReturnToDifferentLocation(value);
    if (nothingToDo) return;
    setFormData((prevData) => {
      const newFormData = {
        ...prevData,
        reservation: {
          ...prevData.reservation,
          [RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID]:
            prevData.reservation[RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID],
        },
      };
      return newFormData;
    });
    setLocations((prevData) => ({
      ...prevData,
      dropoff: mainWidgetData.dropoff.locations,
    }));
  };

  const shouldUpdateDropoffLoc = (form) => (isMobile && !returnToDifferentLocation)
    || !form[RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID];

  const handleFormDataChange = (key, value) => {
    const isDate = key === RENTAL_WIDGET_FIELDS.PICKUP_DATE
      || key === RENTAL_WIDGET_FIELDS.DROPOFF_DATE;
    let newFormData = {};
    let newParamsData = {};
    if (key === RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID) {
      if (shouldUpdateDropoffLoc(formData.reservation)) {
        newFormData = {
          ...formData,
          reservation: {
            ...formData.reservation,
            [RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID]: value,
            [RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID]: value,
          },
        };
        const initialData = initFormData();
        newParamsData = {
          ...initialData,
          ...newFormData,
          reservation: {
            ...initialData?.reservation,
            [RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID]: value,
            [RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID]: value,
            [RENTAL_WIDGET_FIELDS.VEHICLES_COUNT]:
              newFormData?.reservation?.vehicles_count,
            [RENTAL_WIDGET_FIELDS.PICKUP_DATE]: null,
            [RENTAL_WIDGET_FIELDS.DROPOFF_DATE]: null,
            [RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT]: null,
            [RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT]: null,
          },
        };
      } else {
        newFormData = {
          ...formData,
          reservation: {
            ...formData.reservation,
            [RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID]: value,
          },
        };
        const initialData = initFormData();
        newParamsData = {
          ...initialData,
          ...newFormData,
          reservation: {
            ...initialData?.reservation,
            [RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID]: value,
            [RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID]:
              newFormData.reservation[RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID],
            [RENTAL_WIDGET_FIELDS.VEHICLES_COUNT]:
              newFormData?.reservation?.vehicles_count,
            [RENTAL_WIDGET_FIELDS.PICKUP_DATE]: null,
            [RENTAL_WIDGET_FIELDS.DROPOFF_DATE]: null,
            [RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT]: null,
            [RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT]: null,
          },
        };
      }
    } else if (key === RENTAL_WIDGET_FIELDS.PICKUP_DATE) {
      if (autoOpenDropoffDate) {
        setOpenDropoffDatePicker(true);
      }
      newFormData = {
        ...formData,
        reservation: {
          ...formData.reservation,
          [key]: dayjs(value),
        },
      };
      newParamsData = {
        ...newFormData,
        reservation: {
          ...newFormData.reservation,
          [key]: dayjs(value).format(localeDateFormatMap[appNavigator.locale]),
          [RENTAL_WIDGET_FIELDS.DROPOFF_DATE]: null,
          [RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT]: null,
          [RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT]: null,
          [RENTAL_WIDGET_FIELDS.VEHICLES_COUNT]:
            newFormData?.reservation?.vehicles_count,
        },
      };
    } else {
      newFormData = {
        ...formData,
        reservation: {
          ...formData.reservation,
          [key]: isDate ? dayjs(value) : value,
        },
      };
      newParamsData = {
        ...newFormData,
        reservation: {
          ...newFormData.reservation,
          [key]: value,
          [RENTAL_WIDGET_FIELDS.DROPOFF_DATE]: dayjs(
            newFormData.reservation.dropoff_date,
          ).format(localeDateFormatMap[appNavigator.locale]),
          [RENTAL_WIDGET_FIELDS.PICKUP_DATE]: dayjs(
            newFormData.reservation.pickup_date,
          ).format(localeDateFormatMap[appNavigator.locale]),
        },
      };
    }
    setFormData(newFormData);
    if (
      key != RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT
      && key !== RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT
      && key !== RENTAL_WIDGET_FIELDS.VEHICLES_COUNT
    ) {
      getRentalWidgetData(newParamsData, key, newFormData);
    }
  };

  const searchLocationsGeographicalData = (query, availableLocations) => {
    const weights = [6, 1, 1, 1, 1, 1]; // [location_name, airports, market, city, region, country]
    const result = [];

    mainWidgetData.locations_geographical_data.forEach((location) => {
      let locationWeight = 0;
      let index = 0;
      Object.values(location.data).forEach((value) => {
        if (JSON.stringify(value).indexOf(query) > -1) {
          locationWeight += weights[index];
        }
        index += 1;
      });
      if (locationWeight > 0) {
        result.push({
          weight: locationWeight,
          display_name: location.data.display_name,
          region_name: location.data.region_display_name,
          id: location.id,
        });
      }
    });

    const displayedLocations = [];
    for (const location of availableLocations) {
      const loc = result.find((foundLoc) => foundLoc.id === location.id);
      if (loc) {
        displayedLocations.push({
          ...loc,
          ...location,
        });
      }
    }

    return displayedLocations.sort((a, b) => b.weight - a.weight);
  };
  const distance = (lat1, lon1, lat2, lon2) => {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    }

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2)
      + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist *= 0.8684;
    return dist;
  };
  const getNearbyLocations = (locationsList, lat, lng, formattedAddress) => {
    const result = [];
    locationsList.forEach((location) => {
      const dist = distance(lat, lng, location.latitude, location.longitude);
      if (dist < MAX_DISTANCE) {
        result.push({
          ...location,
          distance: Math.round(dist),
          formattedAddress,
        });
      }
    });

    return result;
  };
  const findLocationWithGeoCoder = (type, locationsList, query) => {
    axios
      .get(API.here, {
        params: {
          q: query,
          apiKey: envVars.HERE_API_KEY,
        },
        signal: controller.signal,
      })
      .then((res) => {
        const data = res.data.items[0];
        if (data) {
          const { lat, lng } = data.position;
          const formattedAddress = data.address.label;

          const nearByLocations = getNearbyLocations(
            locationsList,
            lat,
            lng,
            formattedAddress,
          );
          nearByLocations.sort((a, b) => a.distance - b.distance);
          setLocations((prev) => ({
            ...prev,
            [type]: nearByLocations,
          }));
        } else {
          setLocations((prev) => ({
            ...prev,
            [type]: [],
          }));
        }
      })
      .catch(() => {
        setLocations((prev) => ({
          ...prev,
          [type]: [],
        }));
      })
      .finally(() => setIsOptionsLoading((prevData) => ({
        ...prevData,
        [type]: false,
      })));
  };
  const searchLocations = (type, locationsList, input) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (controller) {
      controller.abort();
    }
    setIsOptionsLoading((prevData) => ({
      ...prevData,
      [type]: false,
    }));
    controller = new AbortController();
    const query = input.split(', ')[0].trim().toLowerCase();
    const availableLocations = mainWidgetData[type].locations;
    const sortedGeographicalLocations = searchLocationsGeographicalData(
      query,
      availableLocations,
    );
    if (sortedGeographicalLocations?.length) {
      setLocations((prev) => ({
        ...prev,
        [type]: sortedGeographicalLocations,
      }));
      return;
    }
    setIsOptionsLoading((prevData) => ({
      ...prevData,
      [type]: true,
    }));
    timeoutId = setTimeout(() => {
      findLocationWithGeoCoder(type, locationsList, query);
    }, 500);
  };
  const handleLocationInputChange = (type, input) => {
    if (type === LOCATION_TYPE.PICKUP) {
      searchLocations(type, mainWidgetData.pickup.locations, input);
    } else searchLocations(type, mainWidgetData.dropoff.locations, input);
  };

  const motorcycleList = useMemo(() => {
    if (!mainWidgetData?.group_discounts) return [];
    const groupDiscounts = [{ number: '1' }];
    Object.entries(mainWidgetData.group_discounts).forEach(([key, value]) => {
      groupDiscounts.push({
        number: key.match(/\d+/g)[0],
        discount: (mainWidgetData.show_widget_group_discounts && value) || null,
      });
    });

    return groupDiscounts;
  }, [mainWidgetData.group_discounts]);

  useEffect(() => {
    if (rentalWidgetInitData) {
      updateWidgetData(rentalWidgetInitData, undefined, formData);
    } else {
      getRentalWidgetData(
        {
          ...formData,
          ...firstCallParams,
          reservation: {
            ...formData.reservation,
            [RENTAL_WIDGET_FIELDS.DROPOFF_DATE]:
              formData?.reservation?.dropoff_date
              && dayjs(formData?.reservation?.dropoff_date).format(
                localeDateFormatMap[appNavigator.locale],
              ),
            [RENTAL_WIDGET_FIELDS.PICKUP_DATE]:
              formData?.reservation?.pickup_date
              && dayjs(formData?.reservation?.pickup_date).format(
                localeDateFormatMap[appNavigator.locale],
              ),
            ...firstCallParams?.reservation,
          },
        },
        undefined,
        formData,
      );
    }
  }, [reInitWidget]);
  useEffect(() => {
    if (formData) {
      getFormData(formData);
    }
  }, [formData]);

  return (
    <Box>
      <RentalWidget
        pickupData={{
          locations: locations.pickup,
          minDate: mainWidgetData.pickup.min_date,
          maxDate: mainWidgetData.pickup.max_date,
          timeSlots: mainWidgetData.pickup.time_slots,
        }}
        dropoffData={{
          locations: locations.dropoff,
          minDate: mainWidgetData.dropoff.min_date,
          maxDate: mainWidgetData.dropoff.max_date,
          timeSlots: mainWidgetData.dropoff.time_slots,
        }}
        disableVehicleCount={disableVehicleCount || mainWidgetData.for_vehicle}
        rentalWidgetNotifications={rentalWidgetNotifications}
        motorcycleList={motorcycleList}
        handleLocationInputChange={handleLocationInputChange}
        formData={formData}
        handleFormDataChange={handleFormDataChange}
        widgetType={widgetType}
        handleWidgetTypeChange={handleWidgetTypeChange}
        shouldDisableDate={shouldDisableDate}
        returnToDifferentLocation={returnToDifferentLocation}
        handleReturnToDifferentLocation={handleReturnToDifferentLocation}
        isOptionsLoading={isOptionsLoading}
        handleSearchButtonClick={handleSearchButtonClick}
        handleRenderAllWidget={handleRenderAllWidget}
        errors={rentalWidgetErrors || errors}
        vertical={vertical}
        actionButtonCustomLabel={actionButtonCustomLabel}
        hasToReturnToDifferentLocation={hasToReturnToDifferentLocation}
        isDealWidget={isDealWidget}
        {...other}
        hiddenVehicleCount={hiddenVehicleCount}
        hiddenSearchBtn={hiddenSearchBtn}
        openDropoffDatePicker={openDropoffDatePicker}
        resetOpenDropoffDatePicker={resetOpenDropoffDatePicker}
        recaptchaRef={recaptchaRef}
        recaptchaVehicleCountThreshold={
          mainWidgetData.recaptcha_vehicle_count_threshold
        }
      />
    </Box>
  );
};

export { RentalWidgetWrapper };
