/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
/* eslint-disable prefer-destructuring */
import React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import axios, { API } from 'lib/config/axios';

import getConfig from 'next/config';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
// React components
import LightTextField from 'views/common/components/Logical/Layout/LayoutTextField';
// Material UI icons
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import Typography500 from 'views/common/components/UI/Typography500';
import { useTranslation } from 'next-i18next';
import { CountriesAutocomplete } from 'views/common/components/UI/CountriesAutocomplete';
import erTheme from 'lib/config/theme';

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const PREFIX = 'CustomTripDialog';

const classes = {
  paper: `${PREFIX}-paper`,
  dialogContent: `${PREFIX}-dialogContent`,
  container: `${PREFIX}-container`,
  dialogImg: `${PREFIX}-dialogImg`,
  dialogBody: `${PREFIX}-dialogBody`,
  formWrapper: `${PREFIX}-formWrapper`,
  form: `${PREFIX}-form`,
  send: `${PREFIX}-send`,
  success: `${PREFIX}-success`,
  successIcon: `${PREFIX}-successIcon`,
  thankyou: `${PREFIX}-thankyou`,
  successMsg: `${PREFIX}-successMsg`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: '90%',
    maxHeight: '90vh',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxHeight: '100vh',
      margin: 0,
      height: '100%',
      borderRadius: 0,
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
  },

  [`& .${classes.dialogContent}`]: {
    padding: '0 !important',
  },

  [`& .${classes.container}`]: {
    display: 'flex',
  },

  [`& .${classes.dialogImg}`]: {
    display: 'flex',
  },

  [`& .${classes.dialogBody}`]: { width: '100%;' },

  [`& .${classes.formWrapper}`]: {
    padding: theme.spacing(4, 7),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2, 2.5),
    },
  },

  [`& .${classes.form}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 0,
    },
    "& > [class*='MuiGrid-container']": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.send}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.success}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(16, 6, 0),
  },

  [`& .${classes.successIcon}`]: {
    '& svg': {
      fontSize: '4rem',
      color: theme.palette.success.main,
    },
  },
  [`& .${classes.thankyou}`]: {
    color: theme.palette.success.main,
    margin: theme.spacing(1, 1, 2),
  },
  [`& .${classes.successMsg}`]: {
    color: theme.palette.grey[300],
  },
}));

const initialState = {
  category_id: '',
  lead_type_id: '',
  reservation_number: '',
  comments: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  'g-recaptcha-response': '',
  referring_page: null,
  user_id: null,
};

const CustomTripDialog = (props) => {
  // Exclude `tReady` to not be passed to Dialog through ...other
  const { onClose, open, ...other } = props;

  const dispatch = useDispatch();
  const appState = useSelector((state) => state.globalData.appState);
  const handleErrorResponse = useErrorsHandler();
  const { t } = useTranslation();
  const recaptchaRef = React.useRef();
  const { agentLocationInfo } = useSelector((state) => state.globalData);
  const [value, setValue] = React.useState({
    ...initialState,
    country_code: agentLocationInfo ? agentLocationInfo.code : 'US',
  });

  const [errorMsgs, setErrorMsgs] = React.useState({});

  const [completed, setCompleted] = React.useState(false);

  React.useEffect(() => {
    if (appState?.custom_trip) {
      setValue({
        ...value,
        ...(appState.current_user ? appState.current_user : {}),
        lead_type_id: appState.custom_trip.lead_type_id,
        category_id: appState.custom_trip.category_id,
      });
    }
  }, [appState]);

  const handleInputChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsgs({});
    try {
      const { 'g-recaptcha-response': recaptchaResponse, ...rest } = value;
      await axios.post(API.contactUs, {
        authenticity_token: appState.authenticity_token,
        'g-recaptcha-response': recaptchaResponse,
        contact_info: {
          ...rest,
          referring_page: window.location.href,
          user_id: appState.current_user ? appState.current_user.id : null,
        },
      });
      setCompleted(true);
      setValue(initialState); // Reset dialog
    } catch (error) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      let message = '';
      let isExpected = false;
      if (error.response?.status && error.response.data) {
        const errorData = error.response.data;
        switch (error.response.status) {
          case 406:
            if (errorData.errors) {
              setErrorMsgs({
                ...errorData.errors.contact_info,
              });
              if (errorData.errors.contact_info.base) {
                message = errorData.errors.contact_info.base[0];
              }
            }
            isExpected = true;
            break;
          case 403:
            message = errorData.message;
            isExpected = true;
            break;
        }
      }
      if (message) {
        dispatch(setSnackbar({ open: true, severity: 'error', message }));
      }
      if (!isExpected) {
        handleErrorResponse(error);
      }
    }
  };

  const onRecaptchChange = (token) => {
    setValue({
      ...value,
      'g-recaptcha-response': token,
    });
  };

  const handleClose = () => {
    onClose();
    setCompleted(false);
  };

  const form = (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.first_name)}
            fullWidth
            color="secondary"
            label={t('fe_er_custom_trip_component:first_name')}
            variant="outlined"
            name="first_name"
            value={value.first_name || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.first_name}
            disabled={Boolean(appState.current_user?.first_name)}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.last_name)}
            fullWidth
            color="secondary"
            label={t('fe_er_custom_trip_component:last_name')}
            variant="outlined"
            name="last_name"
            value={value.last_name || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.last_name}
            disabled={Boolean(appState.current_user?.last_name)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.email)}
            fullWidth
            color="secondary"
            label={t('fe_er_custom_trip_component:email')}
            type="email"
            variant="outlined"
            name="email"
            value={value.email || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.email}
            disabled={Boolean(appState.current_user?.email)}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.phone)}
            fullWidth
            color="secondary"
            label={t('fe_er_custom_trip_component:phone_number')}
            type="tel"
            variant="outlined"
            name="phone"
            value={value.phone || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.phone}
            disabled={Boolean(appState.current_user?.phone)}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <ThemeProvider theme={erTheme}>
            <CountriesAutocomplete
              label={t('fe_er_custom_trip_component:country')}
              value={value.country_code}
              name="country_code"
              onChange={(_, code) => {
                handleInputChange({
                  target: {
                    name: 'country_code',
                    value: code,
                  },
                });
              }}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          {envVars.RECAPTCHA_SITE_KEY && (
            <div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={envVars.RECAPTCHA_SITE_KEY}
                onChange={onRecaptchChange}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.send}>
            {t('fe_er_custom_trip_component:get_started')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <StyledDialog
      id="custom-trip-dialog"
      open={open}
      classes={{
        paper: classes.paper,
      }}
      maxWidth="xl"
      keepMounted
      onClose={handleClose}
      {...other}>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.container}>
          <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet="/imgs/custom-trip.jpg"
              />
              <source
                media="(min-width: 600px)"
                srcSet="/imgs/custom-trip-tablet.jpg"
              />
              <img className={classes.dialogImg} src="/imgs/custom-trip.jpg" />
            </picture>
          </Box>
          <div className={classes.dialogBody}>
            {!completed ? (
              <div className={classes.formWrapper}>
                <Grid container justifyContent="space-between" mb={1}>
                  <Grid item>
                    <Typography500 variant="h3" component="h5">
                      {t('fe_er_custom_trip_component:custom_trip_information')}
                    </Typography500>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={handleClose}
                      aria-label="Cancel"
                      color="secondary"
                      size="large">
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography variant="subtitle2" component="p">
                  {t('fe_er_custom_trip_component:custom_trip_description')}
                </Typography>
                {form}
              </div>
            ) : (
              <div className={classes.success}>
                <div className={classes.successIcon}>
                  <CheckCircleIcon />
                </div>
                <Typography500
                  variant="h5"
                  component="p"
                  className={classes.thankyou}>
                  {t('fe_er_custom_trip_component:thank_you')}
                </Typography500>
                <Typography
                  variant="h5"
                  component="p"
                  className={classes.successMsg}>
                  {t('fe_er_custom_trip_component:one_step_closer')}
                  &nbsp;
                  <b>{t('fe_er_custom_trip_component:contact_you_soon')}</b>
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default CustomTripDialog;
