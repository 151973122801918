/**
 * Note:// quotes will be in this format
 * [
 *  label:'Trip Price',
 *  items:[
 *    {
 *        id: "daily_price_1",
 *        description:"$150.98 Base Price × 4 days",
 *        total:"$603.92",
 *        tooltip:{
 *            open:false,
 *            title:"daily rate base price tooltip title"
 *        }
 *    }
 *  ]
 * ]
 * Note:// totalQuotes will be in this format
 * [{
 *  name: 'name',
 *  total: price in numbers
 * }]
 */

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  ClickAwayListener,
  Divider,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Typography500 from 'views/common/components/UI/Typography500';

const PREFIX = 'VrQuote';

const classes = {
  category: `${PREFIX}-category`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  boldCategory: `${PREFIX}-boldCategory`,
  items: `${PREFIX}-items`,
  item: `${PREFIX}-item`,
  questionIcon: `${PREFIX}-questionIcon`,
  right: `${PREFIX}-right`,
  tooltip: `${PREFIX}-tooltip`,
  divider: `${PREFIX}-divider`,
  bold: `${PREFIX}-bold`,
  bolder: `${PREFIX}-bolder`,
  minus: `${PREFIX}-minus`,
  total: `${PREFIX}-total`,
  totalItem: `${PREFIX}-totalItem`,
  boldItem: `${PREFIX}-boldItem`,
  totalItemBox: `${PREFIX}-totalItemBox`,
  lastTotalItem: `${PREFIX}-lastTotalItem`,
  memberSave: `${PREFIX}-memberSave`,
};

const StyledBox = styled(Box)(({ theme, withContainer }) => ({
  [`& .${classes.category}`]: {
    marginBottom: withContainer ? theme.spacing(0) : theme.spacing(1.25),
  },
  [`& .${classes.categoryTitle}`]: {
    marginBottom: theme.spacing(1.25),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.spacing(3.25),
    fontWeight: 500,
    textTransform: 'capitalize',
    [`&.${classes.boldCategory}`]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.spacing(3.75),
    },
  },
  [`& .${classes.items}`]: {
    marginBottom: withContainer ? theme.spacing(0) : theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '& .MuiGrid-item': {
      paddingBottom: theme.spacing(1.5),
    },
    [`& .${classes.item}`]: {
      marginBottom: 0,
      marginTop: 0,
      fontSize: theme.typography.pxToRem(16),
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.pxToRem(14),
      },
      [`& .${classes.questionIcon}`]: {
        fill: 'black',
        fontSize: theme.typography.pxToRem(17),
        marginLeft: theme.typography.pxToRem(5),
        verticalAlign: 'text-top',
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.pxToRem(15),
        },
      },
    },
  },
  [`& .${classes.right}`]: {
    textAlign: 'right',
    fontWeight: 500,
  },
  [`& .${classes.divider}`]: {
    margin: withContainer ? theme.spacing(1, -1) : theme.spacing(1, 0),
  },
  [`& .${classes.bold}`]: {
    fontWeight: 500,
  },
  [`& .${classes.bolder}`]: {
    fontWeight: 800,
    fontSize: `${theme.typography.pxToRem(18)} !important`,
  },
  [`& .${classes.minus}`]: {
    color: theme.palette.error.light,
  },
  [`& .${classes.total}`]: {
    background: theme.palette.grey[50],
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.totalItem}`]: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.spacing(3),
    margin: 0,
    [`&.${classes.middleItem}`]: {
      padding: theme.spacing(2),
    },
    [`&.${classes.boldItem}`]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.spacing(3.25),
      fontWeight: 500,
    },
  },
  [`& .${classes.totalItemBox}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    [`&.${classes.lastTotalItem}`]: {
      borderBottom: 'none',
    },
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(13),
  },
}));

const VrQuote = (props) => {
  const { data, classes: styles = {}, className } = props;
  const { quotes, totalQuotes } = data;

  const [quotesArr, setQuotesArr] = useState(quotes);

  useEffect(() => {
    setQuotesArr(quotes);
  }, [quotes]);

  // set tooltips for quotes
  const handleTooltipToggle = (label, id, show) => () => {
    const categoryIndex = quotes.findIndex((quote) => quote.label == label);
    if (categoryIndex !== -1) {
      let newQuotes = [];
      const newCategoryItems = quotes[categoryIndex].items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            tooltip: {
              ...item.tooltip,
              open: !!show,
            },
          };
        }
        return item;
      });
      newQuotes = [
        ...quotes.slice(0, categoryIndex),
        {
          ...quotes[categoryIndex],
          items: newCategoryItems,
        },
        ...quotes.slice(categoryIndex + 1),
      ];
      setQuotesArr(newQuotes);
    }
  };

  return (
    <StyledBox withContainer={data.withContainer} className={className}>
      {quotesArr?.map((category, index) => (
        <div
          className={clsx(classes.category, styles.category)}
          key={`${category.label}`}>
          {category.items.length > 0 && (
            <>
              <Typography
                className={`${classes.categoryTitle} ${styles.categoryTitle} ${
                  index == 0 ? classes.boldCategory : ''
                }`}>
                {category.label}
              </Typography>
              <div className={clsx(classes.items, styles.items)}>
                {category.items.map((item, itemIndex) => (
                  <React.Fragment key={`${category.label}`}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="space-between"
                      style={{
                        backgroundColor: item.backgroundColor || 'transparent',
                        marginBottom: 0,
                        flexWrap: 'nowrap',
                      }}>
                      <Grid item>
                        {item.tooltip ? (
                          <ClickAwayListener
                            onClickAway={handleTooltipToggle(
                              category.label,
                              item.id,
                              false,
                            )}>
                            <p
                              className={clsx(classes.item, {
                                [classes.bold]: item.backgroundColor,
                                [classes.minus]: item.minus,
                              })}>
                              {item.description}
                              <CustomTooltip
                                title={item.tooltip.title}
                                arrow
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                open={item.tooltip.open}>
                                <HelpOutlineIcon
                                  onClick={handleTooltipToggle(
                                    category.label,
                                    item.id,
                                    true,
                                  )}
                                  className={classes.questionIcon}
                                />
                              </CustomTooltip>
                            </p>
                          </ClickAwayListener>
                        ) : (
                          <p
                            className={clsx(classes.item, {
                              [classes.bold]: item.backgroundColor,
                            })}>
                            {item.description}
                          </p>
                        )}
                      </Grid>
                      <Grid item>
                        <p
                          className={clsx(
                            classes.item,
                            styles.item,
                            styles.right,
                            classes.right,
                            {
                              [classes.bolder]: item.backgroundColor,
                              [classes.minus]: item.minus,
                            },
                          )}>
                          {item.total}
                        </p>
                      </Grid>
                    </Grid>
                    {itemIndex !== category.items.length - 1 && (
                      <Divider
                        className={clsx(classes.divider, styles.divider)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      ))}

      {/* =========== skeleton ========== */}
      {!quotesArr && (
        <>
          <Box my={2}>
            <Skeleton variant="rectangular" height={26} width="20%" />
          </Box>
          {[...Array(3)].map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={classes.category} key={index}>
              <div className={classes.items}>
                <Grid container spacing={0}>
                  <Grid item md={9} xs={8}>
                    <Skeleton variant="rectangular" height={25} width="90%" />
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <Skeleton variant="rectangular" height={25} width="100%" />
                  </Grid>
                </Grid>
              </div>
            </div>
          ))}
        </>
      )}

      {/* =========== TOTALS ========== */}
      <>
        {totalQuotes ? (
          <div className={clsx(classes.root, styles.root)}>
            <div className={clsx(classes.total, styles.total)}>
              {totalQuotes?.map((quoteItem, index) => (
                <Box
                  className={`${classes.totalItemBox}
                ${styles.totalItemBox}
                ${
                  index == totalQuotes.length - 1 ? classes.lastTotalItem : ''
                }`}
                  key={quoteItem.name}>
                  <p
                    className={`${classes.totalItem} ${styles.totalItem} ${
                      index == 0 ? classes.boldItem : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: quoteItem.name,
                    }}
                  />
                  <p
                    className={`${classes.totalItem} ${styles.totalItem} ${
                      styles.right
                    } ${classes.right} ${index == 0 ? classes.boldItem : ''}`}>
                    {quoteItem.total}
                  </p>
                </Box>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>

      {/* =========== membershipSection ========== */}
      {data.seasonalSucharges && (
        <Box mb={1}>
          <Typography
            variant="body2"
            component="p"
            mb={1}
            className={classes.memberSave}>
            <InfoIcon fontSize="inherit" />
            <Typography
              variant="body2"
              component="span"
              ml={1}
              dangerouslySetInnerHTML={{
                __html: data.memberSave,
              }}
            />
          </Typography>
          <Divider />
          <Divider />
          <Typography500 variant="h5" component="p" mt={2} mb={1.5}>
            {data.seasonalSucharges.seasonalTitle}
            <sup>*</sup>
          </Typography500>

          {data.seasonalSucharges.sucharges.map((item) => (
            <Grid container spacing={0.4} key={item.title}>
              <Grid item xs={5} pb={0.5}>
                <Typography variant="caption" component="p">
                  {item.title}
                </Typography>
              </Grid>
              <Grid item xs={4} pb={0.5}>
                <Typography variant="caption" component="p">
                  {item.date}
                </Typography>
              </Grid>
              <Grid item xs={3} pb={0.5}>
                <Typography
                  variant="caption"
                  component="p"
                  pl={0.5}
                  dangerouslySetInnerHTML={{ __html: item.days }}
                />
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </StyledBox>
  );
};

export { VrQuote };
